<template>
  <div class="contentbody">
    <div class="contentleft" v-if="w.left" v-bind:class="{ contentleft: w.right }">
      <PeopleList showOpen />
    </div>
    <div class="contentright" v-if="w.right">
      <Person :inperson="ActivePerson" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Vue from 'vue'
// import Vuex from 'vuex'
// Vue.use(Vuex)
import store from "../store";
import debounce from "lodash/debounce";
import ObjectID from "bson-objectid";
import { Utils } from "../mixins/utils.js";
import { EventBus } from "../event-bus.js";
import { ToastProgrammatic as Toast } from "buefy";
import { windowSplit2 } from "../mixins/windowSplit.js";
import { dbFunctions } from "../mixins/dbMixins.js";
import PeopleList from "@/components/PeopleList.vue";
import PersonListing from "@/components/PersonListing.vue";
import Person from "@/components/Person.vue";

export default {
  mixins: [Utils, windowSplit2, dbFunctions],
  name: "people",
  store,
  components: {
    PeopleList,
    PersonListing,
    Person
  },
  data() {
    return {
      // PeopleList: [],
      GoalList: [],
      searchField: "",
      searchGoal: { _id: "all", goaltitle: "All Projects", goalcolor: "" },
      searchGoalId: "",
      searchGoalCol: "",
      quickTaskField: "",
      newQTask: {},
      ActivePerson: {},
      TeamLive: localStorage.getItem("TeamLive"),
      TeamLiveName: localStorage.getItem("TeamLiveName"),
      TeamLiveColour: localStorage.getItem("TeamLiveColour")
    };
  },

  computed: {},

  async created() {
    // const sub = sub();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    EventBus.$on("person_open", data => {
      this.personOpen(data);
    });
    EventBus.$on("close_right", data => {
      this.closeRight();
    });
    EventBus.$on("do_search", data => {
      this.doSearch();
    });
    EventBus.$on("team_changed", data => {
      this.searchField = "";
      this.closeRight();
      this.getTeam();
      this.loadPeople();
    });
    // this.getGoals();
    // this.loadPeople();
  },
  methods: {
    getTeam() {
      this.TeamLive = localStorage.getItem("TeamLive");
      this.TeamLiveName = localStorage.getItem("TeamLiveName");
      this.TeamLiveColour = localStorage.getItem("TeamLiveColour");
      this.getGoals();
    },

    async getGoals() {
      let query = { deleted: false };
      if (this.TeamLive !== "") {
        let query = { deleted: false, "availableto._id": this.TeamLive };
      }
      const options = { fields: { goaltitle: 1, goalcolor: 1 } };
      const ingl = await this.DataGet('/goals/select');
      this.GoalList = ingl;
      this.GoalList.unshift({
        _id: "all",
        goaltitle: "All Projects",
        goalcolor: ""
      });
    },

    switchHeader() {},

    async loadPeople() {
      let query = { deleted: false };
      if (this.searchField !== "") {
        const qstr = `.*${this.searchField}.*`;
        query = { firstname: { $regex: qstr, $options: "i" }, deleted: false };
      }
      if (this.searchGoal._id !== "all") {
        query.goalid = this.searchGoal._id;
      }
      const options = {
        fields: {
          firstname: 1,
          lastname: 1,
          persontype: 1,
          personrole: 1,
          oneliner: 1,
          occupation: 1,
          original: 1
        }
      };
      Toast.open("Fetching people...");
      this.PeopleList = await this.getData(
        "people",
        query,
        options,
        "peoplelist",
        "none",
        "searchextended"
      );
      Toast.open("People loaded...");
    },

    doSearch: debounce(function() {
      this.loadPeople();
    }, 2000),

    personOpen(p) {
      this.ActivePerson = p;
      this.openRight();
    },

    personAdd(method) {
      if (this.quickTaskField === "") {
        this.$buefy.snackbar.open({
          message: "You can not add a blank task",
          position: "is-top"
        });
        return;
      }
      const newQTask = { ...this.task };
      const id = new ObjectID();
      const idout = id.str;
      newQTask._id = idout;
      newQTask.tasktext = this.quickTaskField;
      newQTask.availableto = [];
      if (this.TeamLive !== "") {
        const newAvail = { _id: this.TeamLive, firstname: this.TeamLiveName };
        newQTask.availableto.push(newAvail);
      }
      if (this.searchGoal._id !== "all") {
        newQTask.goalid = this.searchGoal._id;
        newQTask.goalcolor = this.searchGoal.goalcolor;
      }
      this.updata("tasks", newQTask, "task_added", { method }, false);
    },
    personSave() {}
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    EventBus.$off('person_open');
    EventBus.$off('close_right');
    EventBus.$off('do_search');
    EventBus.$off('team_changed');
  }
};
</script>
