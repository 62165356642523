<template>
  <section>
    <form @submit.prevent="submitForm">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Team Member</p>
        </header>
        <section class="modal-card-body">
          <b-field label="First Name">
            <b-input
              type="text"
              v-model="tm.firstname"
              placeholder="Required"
              required
            ></b-input>
          </b-field>
          <b-field label="Last Name">
            <b-input
              type="text"
              v-model="tm.lastname"
              placeholder="Optional"
            ></b-input>
          </b-field>
          <b-field label="E-mail">
            <b-input
              type="email"
              v-model="tm.email"
              placeholder="Optional"
            ></b-input>
          </b-field>
          <b-field label="Message">
            <b-input
              type="textarea"
              v-model="teamMessage"
              placeholder="Optional"
            ></b-input>
          </b-field>
          <div class="field">
            <b-checkbox v-model="tm.peopleadmin">Team Admin</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="tm.projectadmin"
              >Project/Goal Admin</b-checkbox
            >
          </div>
          <div class="field">
            <b-checkbox v-model="tm.tasksadd">Tasks: add</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="tm.tasksedit">Tasks: edit</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="tm.tasksassign">Tasks: Assign</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="tm.taskscomplete">Tasks: Complete</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="tm.tasksarchive">Tasks: Archive</b-checkbox>
          </div>
          <div class="field">
            <b-checkbox v-model="tm.tasksdelete">Tasks: Delete</b-checkbox>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="$parent.close()">
            Close
          </button>
          <button v-if="mode === 'edit'" class="button is-primary">
            Save
          </button>
          <button v-if="mode === 'invite'" class="button is-primary">
            Send invite
          </button>
          <button
            v-if="mode === 'edit'"
            type="button"
            @click="sendInvite(true)"
            class="button is-primary"
          >
            Re-Send invite
          </button>
        </footer>
      </div>
    </form>
  </section>
</template>

<script>
import { dbFunctions } from "../mixins/dbMixins.js";
import store from "../store";
import { format } from "date-fns";
export default {
  store,
  mixins: [dbFunctions],
  props: {
    mode: String,
    // tm: Object,
    prsn: Object,
    useIndex: Number
  },
  data() {
    return {
      tm: {},
      teamMessage: ""
    };
  },
  created() {
    if (this.mode === "edit") {
      this.tm = this.prsn.inteam[this.useIndex];
    } else {
      this.tm = { ...this.teammember };
    }
  },
  methods: {
    submitForm() {
      if (this.mode === "edit") {
        this.teamSave();
      }
      if (this.mode === "invite") {
        this.sendInvite();
      }
    },

    teamSave() {
      this.updata("people", this.prsn, "none", "person_saved", {}, false);
    },

    async sendInvite(resend) {
      const invcode = this.getUUID(true);
      const senddate = format(new Date(), "yyyy-MM-dd");
      this.tm.invitecode = invcode;
      this.tm.goalid = this.prsn._id;
      this.tm.dt = senddate;
      const newnotify = {
        sub: store.state.user.sub,
        subname: store.state.user.subname,
        userid: "",
        useremail: this.tm.email,
        firstname: "",
        lastname: "",
        type: "Invite",
        subject: `${store.state.user.subname} has invited you to join their team`,
        message: `${this.teamMessage} \n\n----------\n\n
        Your invitation code: ${invcode} \n\n
        To join this Goal/Project login to your account and in the team selector in the top right 
        select Join Team and then enter this e-mail and the code`,
        goalid: this.prsn._id,
        taskid: "",
        personid: "",
        inviteid: invcode,
        status: "pending",
        dt: senddate
      };
      if (!this.prsn.team) {
        this.prsn.team = [];
      }

      if (!resend) {
        this.prsn.inteam.push(this.tm);
        this.updata("people", this.prsn, "none", "person_saved", {}, false);
        // this.personSave();
      }
      const sendinv = await this.livePostPromise(
        "sendinvite",
        "notify",
        newnotify
      );
      if (!resend) {
        // $parent.close();
      }
    }
  }
};
</script>
