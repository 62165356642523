<template>
  <section>
    <div class="modal-card">
      <header class="modal-card-head" style="padding: 10px;">
        <p class="modal-card-title">{{ useTitle }}</p>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="submitForm">
          <div v-for="(row, index) in infields" :key="index">
            <!-- tags:start -->
            <div v-if="row.type === 'heading'" v-bind:class="row.class">
              {{ row.value }}
            </div>
            <div v-if="row.type === 'div'" v-bind:class="row.class">
              {{ row.value }}
            </div>

            <!-- contact numbers: start -->
            <div
              class="isFlex isFlexAlign"
              v-bind:class="row.class"
              v-if="row.type === 'row'"
            >
              <!-- <div class="roundedleft lgreybg p5" style="width: 25px; padding-left: 10px;">
	          			<font-awesome-icon :icon="['fal', 'mobile']" size="sm"></font-awesome-icon>
	          		</div> -->
              <div class="p3 lgreybg p5" style="width: 25%">
                <strong>{{ row.label }}</strong>
                <span v-if="row.label === ''">&nbsp;</span>
              </div>
              <div class="p5 isFlexGrow vlgreybg roundedright">
                {{ row.value }}
              </div>
            </div>
            <!-- contact numbers: end -->

            <!-- tags:end -->

            <b-field
              :label="row.label"
              v-if="row.type === 'input' && row.show"
              style="margin-bottom: 15px;"
            >
              <b-input
                :type="row.subtype"
                v-model="row.value"
                :placeholder="row.placeholder"
                :required="row.required"
                :disabled="row.disabled"
              ></b-input>
            </b-field>

            <!-- switch:start -->
            <div
              class="field"
              v-if="row.type === 'switch'"
              style="margin-bottom: 15px;"
            >
              <b-switch v-model="row.value">
                {{ row.label }}
              </b-switch>
            </div>
            <!-- switch:end -->

            <!-- radio:start -->
            <div
              class="mb15"
              v-bind:class="row.class"
              v-if="row.type === 'radio'"
            >
              <b-radio
                v-model="row.value"
                :name="row.field"
                native-value="row.value"
              >
                {{ row.label }}
              </b-radio>
            </div>
            <!-- radio:end -->

            <!-- number:start -->
            <div
              class="mb15"
              v-bind:class="row.class"
              v-if="row.type === 'number'"
            >
              <b-field :label="row.label">
                <b-numberinput
                  v-model="row.value"
                  size="is-small"
                ></b-numberinput>
              </b-field>
            </div>
            <!-- number:end -->

            <!-- select:start  -->
            <b-field
              :label="row.label"
              v-if="row.type === 'select' && row.show"
              style="margin-bottom: 15px;"
            >
              <b-select
                placeholder="Select..."
                expanded
                v-model="row.value"
                @input="process()"
                :disabled="row.disabled"
              >
                <option
                  v-for="opt in row.options"
                  :value="opt.value"
                  :key="opt.label"
                  >{{ opt.label }}</option
                >
              </b-select>
            </b-field>
            <!-- select:end  -->

            <!-- select:start  -->
            <b-field
              :label="row.label"
              v-if="row.type === 'selectobj' && row.show"
              style="margin-bottom: 15px;"
            >
              <b-select
                placeholder="Select..."
                expanded
                v-model="row.value"
                @input="process()"
                :disabled="row.disabled"
              >
                <option
                  v-for="opt in row.options"
                  :value="opt"
                  :key="opt.label"
                  >{{ opt.label }}</option
                >
              </b-select>
            </b-field>
            <!-- select:end  -->

            <!-- date:start -->
            <b-field
              :label="row.label"
              v-if="row.type === 'date' && row.show"
              style="margin-bottom: 15px;"
            >
              <b-datepicker
                placeholder="Click to select..."
                v-model="row.value"
                icon="calendar"
                :inline="row.inline"
              >
                <button
                  class="button is-primary is-small"
                  @click="row.value = new Date()"
                >
                  <b-icon icon="calendar-day"></b-icon>
                  <span>Today</span>
                </button>

                <button
                  class="button is-danger is-small"
                  @click="row.value = null"
                >
                  <b-icon icon="times"></b-icon>
                  <span>Clear</span>
                </button>
              </b-datepicker>
            </b-field>
            <!-- date:end -->

            <!-- timepicker:start  -->
            <b-field
              :label="row.label"
              v-if="row.type === 'timepicker' && row.show"
              style="margin-bottom: 15px;"
            >
              <b-clockpicker
                v-model="row.value"
                inline
                hour-format="24"
              ></b-clockpicker>
            </b-field>
            <!-- timepicker:end  -->

            <!-- time: start -->
            <b-field
              :label="row.label"
              v-if="row.type === 'time' && row.show"
              style="margin-bottom: 15px;"
            >
              <div class="isFlex">
                <b-select placeholder="Select..." v-model="row.hour">
                  <option
                    v-for="h in timeHours"
                    :value="h.value"
                    :key="h.value"
                    >{{ h.label }}</option
                  >
                </b-select>
                <b-select placeholder="Select..." v-model="row.minutes">
                  <option
                    v-for="h in timeMinutes"
                    :value="h.value"
                    :key="h.value"
                    >{{ h.label }}</option
                  >
                </b-select>
              </div>
            </b-field>
            <!-- time: end -->

            <!-- duration: start -->
            <b-field
              :label="row.label"
              v-if="row.type === 'duration' && row.show"
              style="margin-bottom: 15px;"
            >
              <div class="isFlex">
                <b-select placeholder="Select..." v-model="row.hour">
                  <option
                    v-for="h in timeHours"
                    :value="h.value"
                    :key="h.value"
                    >{{ h.label }}</option
                  >
                </b-select>
                <b-select placeholder="Select..." v-model="row.minutes">
                  <option
                    v-for="h in durationMinutes"
                    :value="h.value"
                    :key="h.value"
                    >{{ h.label }}</option
                  >
                </b-select>
              </div>
            </b-field>
            <!-- duration: end -->
          </div>
        </form>
      </section>
      <footer class="modal-card-foot" style="padding: 10px;">
        <button class="button" type="button" @click="$parent.close()">
          Close
        </button>
        <button type="button" @click="submitForm()" class="button is-primary">
          Submit
        </button>
      </footer>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import { EventBus } from "../event-bus.js";

export default {
  name: "ModalForm",
  props: {
    title: String,
    fields: Array,
    callBus: String,
    busData: Object
  },
  data() {
    return {
      infields: this.fields,
      timeHours: [
        { value: "0", label: "00" },
        { value: "1", label: "01" },
        { value: "2", label: "02" },
        { value: "3", label: "03" },
        { value: "4", label: "04" },
        { value: "5", label: "05" },
        { value: "6", label: "06" },
        { value: "7", label: "07" },
        { value: "8", label: "08" },
        { value: "9", label: "09" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" },
        { value: "21", label: "21" },
        { value: "22", label: "22" },
        { value: "23", label: "23" }
      ],
      timeMinutes: [
        { value: "00", label: "00" },
        { value: "05", label: "05" },
        { value: "10", label: "10" },
        { value: "15", label: "15" },
        { value: "20", label: "20" },
        { value: "25", label: "25" },
        { value: "30", label: "30" },
        { value: "35", label: "35" },
        { value: "40", label: "40" },
        { value: "45", label: "45" },
        { value: "50", label: "50" },
        { value: "55", label: "55" }
      ],
      durationMinutes: [
        { value: "00", label: "00" },
        { value: "15", label: "15" },
        { value: "30", label: "30" },
        { value: "45", label: "45" }
      ],
      useTitle: this.title
    };
  },
  created() {},
  methods: {
    process() {
      for (const field of this.infields) {
        if (field.type === "time") {
          field.timefull = field.hour + field.minutes * 1;
        }
        if (field.type === "duration") {
          field.blocks = field.hour * 4 + (field.minutes * 1) / 15;
        }
        if (field.conditionkey) {
          if (this.fields[field.conditionkey].value === field.conditionvalue) {
            field.show = true;
          } else {
            field.show = false;
          }
        }
      }
    },
    submitForm() {
      const retdata = this.busData;
      retdata["fields"] = this.infields;
      EventBus.$emit(this.callBus, retdata);
    }
  }
};
</script>
