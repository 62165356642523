<template>
  <div class="contentright-inner">
    <!-- navi: start -->
    <div class="contentright-nav">
      <div class="iconbutton mv5" @click="closeRight()">
        <font-awesome-icon :icon="['fal', 'times']" size="lg" ></font-awesome-icon>
      </div>

      <div v-if="mode.view">
        <div class="iconbutton mv15" @click="changeViewView('basic')">
          <font-awesome-icon :icon="['fal', 'user']" size="lg" ></font-awesome-icon>
        </div>
        <!-- <div class="iconbutton mv15" v-if="!fields.showTeam" >
          <font-awesome-icon :icon="['fal', 'map-signs']" size="lg" ></font-awesome-icon>
        </div> -->
        <div class="iconbutton mv15" @click="changeViewView('schedule')" v-if="!fields.showTeam" >
          <font-awesome-icon :icon="['fal', 'calendar-week']" size="lg" ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="changeViewView('connections')" v-if="!fields.showTeam" >
          <font-awesome-icon :icon="['fal', 'chart-network']" size="lg" ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="changeViewView('custom')" v-if="!fields.showTeam">
          <font-awesome-icon :icon="['fal', 'pencil-ruler']" size="lg" ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="changeViewView('notes')">
          <font-awesome-icon :icon="['fal', 'sticky-note']" size="lg" ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="changeViewView('log')" v-if="!fields.showTeam && TeamLive !== ''" >
          <font-awesome-icon :icon="['fal', 'stopwatch']" size="lg" ></font-awesome-icon>
        </div>
      </div>

      <div v-if="mode.view">
        <div class="iconbutton mv5" @click="switchMode('edit')">
          <font-awesome-icon :icon="['fal', 'edit']" size="lg" ></font-awesome-icon>
        </div>
      </div>

      <div v-if="mode.edit">
        <div class="iconbutton mv15" @click="switchMode('view')">
          <font-awesome-icon :icon="['fal', 'eye']" size="lg" ></font-awesome-icon>
        </div>

        <div class="iconbutton mv15" @click="changeViewEdit('basic')">
          <font-awesome-icon :icon="['fal', 'user']" size="lg" ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="changeViewEdit('contact')" v-if="!fields.showTeam" >
          <font-awesome-icon :icon="['fal', 'address-book']" size="lg" ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="changeViewEdit('profile')">
          <font-awesome-icon :icon="['fal', 'id-card']" size="lg" ></font-awesome-icon>
        </div>
        <!-- <div class="iconbutton mt5" @click="changeViewEdit('experience')">
          <font-awesome-icon :icon="['fal', 'monument']" size="sm"></font-awesome-icon>
        </div> -->
        <div class="iconbutton mv15" @click="changeViewEdit('connections')" v-if="!fields.showTeam" >
          <font-awesome-icon :icon="['fal', 'chart-network']" size="lg" ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="changeViewEdit('custom')" v-if="!fields.showTeam" >
          <font-awesome-icon :icon="['fal', 'pencil-ruler']" size="lg" ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15" @click="changeViewEdit('schedule')" v-if="!fields.showTeam" >
          <font-awesome-icon :icon="['fal', 'calendar-week']" size="lg" ></font-awesome-icon>
        </div>

        <div class="iconbutton mv15 bluetxt11" @click="personSave()">
          <font-awesome-icon :icon="['fal', 'save']" size="lg" ></font-awesome-icon>
        </div>
        <div class="iconbutton mv15 red" @click="deleteShow()">
          <font-awesome-icon :icon="['fal', 'trash']" size="lg" ></font-awesome-icon>
        </div>
      </div>
    </div>
    <!-- navi: end -->

    <div class="contentright-body">
      <div class="contentright-body-header p7 shadow" v-if="mode && mode.edit">
        {{ inperson.firstname }} {{ inperson.lastname }}
      </div>

      <!-- delete: start -->
      <div class="contentright-body-header darkgreybg p7" v-if="showDelete">
        <div class="flexGrow white">
          Are you sure you want to delete this contact?
        </div>
        <b-button type="is-danger" class="mr9" @click="deleteDo">Yes</b-button>
        <b-button type="is-link" @click="deleteHide">No</b-button>
      </div>
      <!-- delete: end -->

      <div class="contentright-body-body scrolly m9">
        <!-- view: start -->
        <div v-if="mode.view">
          <!-- view: basic: start -->
          <div v-if="naviview.basic">
            <div style="height: 15px;"></div>

            <!-- image: start -->
            <div class="isFlex isFlexJustify">
              <div class="isPointer">
                <img
                  src="/dummy/120x120.png"
                  class="thumb thumb-xlg thumb-circle"
                  v-if="prsn.photo === ''"
                />
                <img
                  :src="prsn.photo"
                  class="thumb thumb-xlg thumb-circle"
                  v-else
                />
              </div>
            </div>
            <!-- image: end  -->

            <div style="height: 15px;"></div>

            <h3 class="txth2 txtc">
              {{ inperson.firstname }} {{ inperson.lastname }}
            </h3>
            <div class="p3 txtc">
              {{ prsn.oneliner }}
            </div>
            <div class="sp20" style="border-bottom: 1px solid #efefef;"></div>

            <div v-if="!fields.showTeam">
              <div style="height: 15px;"></div>

              <!-- contact numbers: start -->
              <div class="isFlex isFlexAlign mv5" v-for="(ct, index) in prsn.contactnumbers" :key="index">
                <div class="roundedleft lgreybg p5" style="width: 25px; padding-left: 10px;">
                  <font-awesome-icon
                    :icon="['fal', 'mobile']" size="sm" ></font-awesome-icon>
                </div>
                <div class="p3 lgreybg p5" style="width: 25%">
                  <strong> {{ ct.type }}</strong>
                </div>
                <div class="p5 isFlexGrow vlgreybg roundedright">
                  <a :href="'tel:' + ct.number">{{ ct.number }}</a>
                </div>
              </div>
              <!-- contact numbers: end -->

              <!-- email: start -->
              <div
                class="isFlex isFlexAlign mv5"
                v-for="ct in prsn.emails"
                :key="ct.email"
              >
                <div
                  class="roundedleft lgreybg p5"
                  style="width: 25px; padding-left: 5px;"
                >
                  <font-awesome-icon
                    :icon="['fal', 'paper-plane']"
                    size="sm"
                  ></font-awesome-icon>
                </div>
                <div class="lgreybg p5" style="width: 25%">
                  <strong>{{ ct.type }}</strong>
                </div>
                <div class="p5 isFlexGrow vlgreybg roundedright">
                  <a :href="'mailto:' + ct.email">{{ ct.email }}</a>
                </div>
              </div>
              <!-- email: end -->
            </div>

            <!-- team: start -->
            <div v-if="fields.showTeam">
              <div class="isFlex isFlexAlign mb9">
                <div class="isFlexGrow txth4">Team</div>
                <div
                  class="iconbutton"
                  @click="openInvite('invite', {}, -1)"
                  v-if="editAllowTeam"
                >
                  <font-awesome-icon
                    :icon="['fal', 'plus']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
              <div v-if="prsn.inteam.length === 0">
                You have no members in this team. Invite some.
              </div>
              <div
                class="tsk vlgreybg mv7"
                v-for="(irow, index) in prsn.inteam"
                :key="irow.invitecode"
              >
                <div class="tskhead">
                  <div class="tskbody">
                    {{ irow.firstname }} {{ irow.lastname }}
                    <span class="txtsm">({{ irow.status }})</span>
                  </div>
                  <div
                    class="iconbutton mr9"
                    @click="deleteTeamMember(index)"
                    v-if="editAllowTeam && !irow.owner"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'trash']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                  <div
                    class="iconbutton"
                    @click="openInvite('edit', irow, index)"
                    v-if="editAllowTeam || irow.userid === user.sub"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'eye']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                </div>
              </div>
            </div>
            <!-- team: end -->
          </div>
          <!-- view: basic: end -->

          <!-- address: start-->
          <div v-if="naviview.address">
            <div class="isFlex isFlexAlign pb9 mb11">
              <div class="isFlexGrow txth3">Addresses</div>
              <!-- <div>
              	<div class="iconbutton" @click="goToEdit('address')">
                	<font-awesome-icon :icon="['fal', 'edit']" size="lg"></font-awesome-icon>
              	</div>
            	</div> -->
            </div>
            <!-- addresses: start -->
            <div v-for="ct in prsn.addresses" :key="ct.number" class="mb11">
              <div>
                <h4 class="txth4">
                  <font-awesome-icon
                    :icon="['fal', 'map-signs']"
                    size="sm"
                  ></font-awesome-icon>
                  {{ ct.type }}
                </h4>
              </div>

              <!-- address: Start -->
              <div class="isFlex isFlexAlign mv5">
                <div class="p5 lgreybg roundedleft" style="width: 25%">
                  <strong> Address</strong>
                </div>
                <div class="p5 isFlexGrow roundedright vlgreybg">
                  <div>{{ ct.address }}</div>
                </div>
              </div>
              <!-- address: end -->

              <!-- town: Start -->
              <div class="isFlex isFlexAlign mv5">
                <div class="p5 lgreybg roundedleft" style="width: 25%">
                  <strong> Town</strong>
                </div>
                <div class="p5 isFlexGrow roundedright vlgreybg">
                  <div>{{ ct.town }}</div>
                </div>
              </div>
              <!-- town: end -->
              <!-- county: Start -->
              <div class="isFlex isFlexAlign mv5">
                <div class="p5 lgreybg roundedleft" style="width: 25%">
                  <strong> County</strong>
                </div>
                <div class="p5 isFlexGrow roundedright vlgreybg">
                  <div>{{ ct.county }}</div>
                </div>
              </div>
              <!-- county: end -->

              <!-- state: Start -->
              <div class="isFlex isFlexAlign mv5">
                <div class="p5 lgreybg roundedleft" style="width: 25%">
                  <strong> State</strong>
                </div>
                <div class="p5 isFlexGrow roundedright vlgreybg">
                  <div>{{ ct.state }}</div>
                </div>
              </div>
              <!-- state: end -->

              <!-- country: Start -->
              <div class="isFlex isFlexAlign mv5">
                <div class="p5 lgreybg roundedleft" style="width: 25%">
                  <strong> Country</strong>
                </div>
                <div class="p5 isFlexGrow roundedright vlgreybg">
                  <div>{{ ct.country }}</div>
                </div>
              </div>
              <!-- country: end -->
            </div>

            <!-- addresses: end -->
          </div>
          <!-- address: end-->

          <!-- schedule: start  -->
          <div v-if="naviview.schedule">
            <div class="isFlex isFlexAlign mb11">
              <div class="isFlexGrow txth3">Schedule</div>
            </div>

            <div
              class="dtable-row tsk vlgreybg mv7"
              v-for="(av, index) in prsn.schedule"
              :key="index"
            >
              <div class="dtable-row-middle">
                <strong class="txth4">{{ av.day }}</strong>
                <span v-if="av.grind === 'settime'">
                  <span
                    ><br />From
                    <strong
                      >{{ av.timestart.hour }}:{{ av.timestart.minutes }}
                    </strong>
                  </span>
                  <span
                    >To
                    <strong
                      >{{ av.timeend.hour }}:{{ av.timeend.minutes }}
                    </strong>
                  </span>
                </span>
                <span v-else
                  ><br />Duration:
                  <strong
                    >{{ av.duration.hour }}:{{ av.duration.minutes }}
                  </strong>
                  Blocks: <strong>{{ av.duration.blocks }} </strong></span
                >
              </div>
            </div>
            <div class="tsk vlgreybg mv7" v-if="prsn.schedule.length === 0">
              No schedule items setup.
            </div>
          </div>
          <!-- schedule: end  -->

          <!-- connections: start  -->
          <div v-if="naviview.connections">
            <div class="isFlex isFlexAlign mb11">
              <div class="isFlexGrow txth3">Connections</div>
            </div>

            <!-- availableto: start -->
            <!-- <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth4">Availble to team(s)</div>
            </div>
            <div
              class="dtable-row tsk vlgreybg mv7"
              v-for="av in prsn.availableto"
              :key="av._id"
            >
              <div class="dtable-row-middle">
                <strong>{{ av.firstname }}</strong>
              </div>
            </div>
            <div class="tsk vlgreybg mv7" v-if="prsn.availableto.length === 0">
              Only available to me
            </div> -->
            <!-- availableto: ends -->

            <div style="height: 15px;"></div>

            <!-- Associated: start -->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth4">Associated with</div>
            </div>
            <div
              class="dtable-row tsk vlgreybg mv7"
              v-for="av in prsn.inco"
              :key="av._id"
            >
              <div class="dtable-row-middle">
                <strong>{{ av.firstname }}</strong> <br />{{ av.how }}
              </div>
            </div>
            <div class="tsk vlgreybg mv7" v-if="prsn.inco.length === 0">
              No associations setup.
            </div>
            <!-- Associated: ends -->
          </div>
          <!-- connections: end  -->

          <!-- custom: start  -->
          <div v-if="naviview.custom">
            <div class="isFlex isFlexAlign mb11">
              <div class="isFlexGrow txth3">Custom info</div>
            </div>

            <div
              class="isFlex isFlexAlign mv5"
              v-for="ct in prsn.info"
              :key="ct._id"
            >
              <div
                class="roundedleft lgreybg p5"
                style="width: 25px; padding-left: 5px;"
              >
                <font-awesome-icon
                  :icon="['fal', 'pencil-ruler']"
                  size="sm"
                ></font-awesome-icon>
              </div>
              <div class="lgreybg p5" style="width: 25%">
                <strong>{{ ct.key }}</strong>
              </div>
              <div class="p5 isFlexGrow vlgreybg roundedright">
                {{ ct.value }}
              </div>
            </div>
            <div class="tsk vlgreybg mv7" v-if="prsn.inco.length === 0">
              No custom variables setup.
            </div>
          </div>
          <!-- custom: end  -->

          <!-- notes: start  -->
          <div v-if="naviview.notes">
            <div class="isFlex tsk lgreybg p5 mb11">
              <div class="contentleft-header-searchbox">
                <b-input
                  placeholder="Search"
                  type="search"
                  icon="search"
                  rounded
                  v-model="notesSearchField"
                  @input="notesSearch()"
                ></b-input>
              </div>
              <div class="iconbutton mt5" @click="noteAdd('new', {}, -1)">
                <font-awesome-icon
                  :icon="['fal', 'plus-circle']"
                  size="lg"
                ></font-awesome-icon>
              </div>
            </div>

            <div class="tsk mb11 vlgreybg isFlex" v-for="(nt, index) in nts" :key="nt._id" >
              <div class="isFlexGrow">
                <div class="txtsm mb3 isPointer">
                  {{ nt.subname }} |
                  {{ nt.notedt | displaydateTS("HH:mm ddd do MMM YY") }}
                </div>
                <div
                  class="isPointer"
                  v-if="!nt.expand"
                  @click="noteExpand(nt, index)"
                >
                  {{ nt.notepre }}
                </div>
                <div
                  class="txt"
                  v-if="nt.expand"
                  @click="noteExpand(nt, index)"
                  v-html="nt.notehtml"
                ></div>
              </div>
              <div class="isFlex">
                <div class="iconbutton" @click="noteAdd('edit', nt, index)">
                  <font-awesome-icon
                    :icon="['fal', 'edit']"
                    size="sm"
                  ></font-awesome-icon>
                </div>
                <div class="iconbutton" @click="noteDelete(nt, index)">
                  <font-awesome-icon
                    :icon="['fal', 'trash']"
                    size="sm"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div
              v-if="nts.length >= notesLimit"
              class="isFlex isFlexJustify mt11"
            >
              <b-button @click="notesLoadNext()">Load More</b-button>
            </div>
            <div class="tsk vlgreybg mv7" v-if="nts.length === 0">
              No notes found.
            </div>
          </div>
          <!-- notes: end  -->

          <!-- worklog: start  -->
          <div v-if="naviview.log">
            <div class="tsk bluebg8 white mb9" v-if="wlHasOpen">
              <div>You currently have an active session <span v-if="wlActive.person !== ''">(Person): {{wlActive.person}}</span> <span v-if="wlActive.task !== ''">(Task): {{ wlActive.task }} <span v-if="wlActive.goal !== ''"> in {{wlActive.goal}}</span></span></div>
            </div>
            <div class="mb11 tsk lgreybg" v-if="!wlIsOpen">
              <div class="isFlex isFlexJustify">
                <div class="iconbutton5x " style="width; 100px;" @click="wlStart()" >
                  <font-awesome-icon :icon="['fal', 'play-circle']" size="5x" ></font-awesome-icon>
                </div>
              </div>
              <div class="txtc p3">Start a new session</div>
            </div>

            <div class="mb11 tsk lgreybg" v-if="wlIsOpen">
              <textarea class="inputwithbuttontext" v-model="wlSession.description" rows="2" placeholder="What did you do? Did you have any problems?" ></textarea>
              <div class="isFlex isFlexJustify">
                <div class="iconbutton5x " style="width; 100px;" @click="wlStop()" >
                  <font-awesome-icon :icon="['fal', 'stop-circle']" size="5x" ></font-awesome-icon>
                </div>
              </div>
              <div class="txtc p3">Stop session</div>
            </div>

            <div class="mb11 p9 tsk lgreybg isFlex">
              <div class="isFlexGrow mr5">
                <b-datepicker type="month" placeholder="Click to select..." icon="calendar" v-model="wlmonth" ></b-datepicker>
              </div>
              <!-- <div>
                <b-select placeholder="Team" v-model="wlTeam" @input="wlChangeTeam">
                  <option
                      v-for="option in wlTeamList"
                      :value="option"
                      :key="option._id">
                      {{ option.firstname }}
                  </option>
                </b-select> 
              </div> -->
              <!-- <div v-if="editAllowTeam">
                <b-select placeholder="Members" v-model="wlTeamMember">
                  <option v-for="option in wlTeamMembers" :value="option" :key="option._id" >{{ option.firstname }}</option>
                </b-select>
              </div> -->
              <div>
                <b-select placeholder="Select a status" v-model="wlSstatus">
                  <option value="">All entries</option>
                  <option value="new">new</option>
                  <option value="rejected">rejected</option>
                  <option value="approved">approved</option>
                  <option value="exported">exported</option>
                  <option value="processed">processed</option>
                  <option value="paid">paid</option>
                </b-select>
              </div>
              <div>
                <b-button @click="wlShowMonth">Apply</b-button>
                <b-button @click="wlMLshow=true">Manual Entry</b-button>
              </div>
            </div>

            <div v-if="wlMLshow">
              <div class="tsk vlgreybg lgreyborder mb11">
                <div class="txth3 txtbold">Manual log entry</div>
                <b-field label="From date">
                  <b-datepicker v-model="wlStartDate" placeholder="Click to select..." icon="calendar"></b-datepicker>
                </b-field>
                <b-field label="From time">
                  <b-clockpicker v-model="wlStartTime" placeholder="Click to select..." icon="clock" hour-format="24"></b-clockpicker>
                </b-field>
                <b-field label="To date">
                  <b-datepicker v-model="wlEndDate" placeholder="Click to select..." icon="calendar"></b-datepicker>
                </b-field>
                <b-field label="To time">
                  <b-clockpicker v-model="wlEndTime" placeholder="Click to select..." icon="clock" hour-format="24"></b-clockpicker>
                </b-field>
                <b-field label="What did you do?">
                  <textarea class="inputwithbuttontext" v-model="wlMLwhat" rows="2" placeholder="What did you do? Did you have any problems?" ></textarea>
                </b-field>
                <b-field label="Why was it not recorded?">
                  <textarea class="inputwithbuttontext" v-model="wlMLwhy" rows="2" placeholder="Naugthy naughty" ></textarea>
                </b-field>
                <div class="buttons">
                  <b-button @click="wlMLshow=false">Cancel</b-button>
                  <b-button @click="wlMLDo">Record Log</b-button>
                </div>
              </div>
            </div>

            <div v-for="(ss, index) in wlRows" :key="ss._id">
              <div class="tsk p9 mb11 vlgreybg isFlex isFlexAlign" v-if="wlSstatus === ss.islatestatus || wlSstatus === ''"  v-bind:class="{ completed: ss.deleted }">
                <div class="mr5">
                  <div class="red mb3" v-if="ss.islate && ss.islatestatus !== 'approved'"><font-awesome-icon :icon="['fal', 'alarm-exclamation']" size="lg"></font-awesome-icon></div>
                  <div v-else>
                    <div v-if="ss.islatestatus === 'approved'"><b-checkbox v-model="ss.selected"></b-checkbox></div>
                    <div v-else><b-checkbox v-model="ss.selected" disabled></b-checkbox></div>
                    
                    
                  </div>
                </div>
                <div class="isFlexGrow">
                  <div>{{ ss.description }}</div>
                  <div class="txtsm">
                    Who: <strong>{{ ss.subname }}</strong> | Duration:
                    <strong>{{ ss.totalhours }} </strong> Hours
                    <strong>{{ ss.totalminutes }} </strong> Minutes <br />From
                    <strong >{{ ss.startdt.dayst }} {{ ss.startdt.monthtxt }} {{ ss.startdt.year }} {{ ss.startdt.hour | numberStr("0", "pre") }}:{{ss.startdt.minute | numberStr("0", "post")}}</strong>
                    to
                    <strong >{{ ss.enddt.dayst }} {{ ss.enddt.monthtxt }} {{ ss.enddt.year }} {{ ss.enddt.hour | numberStr("0", "pre") }}:{{ ss.enddt.minute | numberStr("0", "post") }}</strong>
                    <span v-if="ss.billed"> (Billed)</span>
                  </div>
                  <div class="txtsm" v-if="ss.deleted">
                    <strong>Delete Reason: </strong>{{ ss.deletedreason }}
                  </div>
                  <div class="txtsm" v-if="ss.islate && !ss.deleted">
                    <strong>Late Reason: </strong>{{ ss.islatereason }}
                    <span> ({{ ss.islatestatus }})</span>
                  </div>
                  <div class="txtsm red" v-if="ss.islatestatus === 'rejected'">
                    <strong>Message: </strong>{{ ss.islatestatusmessage }}
                  </div>
                  <div v-if="!ss.deleted" class="isFlex mt3">
                    <div>
                      <b-input placeholder="Message" v-model="ss.islatestatusmessage" size="is-small"></b-input>
                    </div>
                    <div>
                      <b-select placeholder="Select a status" v-model="ss.islatestatus" size="is-small" @input="wlUpdate(ss)">
                        <option value="new">new</option>
                        <option value="rejected">rejected</option>
                        <option value="approved">approved</option>
                        <option value="exported">exported</option>
                        <option value="processed">processed</option>
                        <option value="paid">paid</option>
                      </b-select>
                    </div>
                    <!-- <div><b-button size="is-small" @click="wlUpdate(ss)">Update</b-button></div> -->
                  </div>
                </div>
              </div>
              <!-- <div class="tsk p7 mb11 vlgreybg" v-else>
                Tap apply to apply your search...
              </div> -->
            </div>

            <div class="tsk p7 mb11 vlgreybg" v-if="wlRows.length === 0">
              No sessions logged.
            </div>

            <div class="mb11 p9 tsk lgreybg isFlex">
              <!-- <div class="mr5">
                <b-button @click="wlEditLog('new', {}, -1)">
                  <font-awesome-icon :icon="['fal', 'plus']" size="lg" ></font-awesome-icon>
                </b-button>
              </div> -->
              <div class="isFlexGrow mr5">
                <b-input v-model="wlFileName"></b-input>
              </div>
              <div class="mr5">
                <b-button @click="wlcsv">CSV</b-button>
              </div>
              <!-- <div>
                <b-button @click="wlbilled">Billed</b-button>
              </div> -->
            </div>
          </div>
          <!-- worklog: end  -->
        </div>
        <!-- view: end -->

        <!-- edit: start -->
        <div v-if="mode.edit">
          <!-- basic: start -->
          <div style="margin-bottom: 20px;" v-if="naviedit.basic">
            <div style="height: 15px;"></div>

            <!-- person type: start -->
            <b-field label="People Type">
              <b-select placeholder="Select..." expanded v-model="prsn.persontype"  >
                <option v-for="tit in optPersonType" :value="tit.value" :key="tit.value" >{{ tit.label }}</option>
              </b-select>
            </b-field>
            <!-- person tour: end -->

            <!-- title: start -->
            <b-field label="Title" v-if="prsn.persontype !== 'Team'">
              <b-select placeholder="Select..." expanded v-model="prsn.salutation">
                <option v-for="tit in optTitles" :value="tit.value" :key="tit.value" >{{ tit.label }}</option>
              </b-select>
            </b-field>
            <!-- title: end -->

            <!-- first: start -->
            <b-field :label="fields.firstname">
              <b-input v-model="prsn.firstname"></b-input>
            </b-field>
            <!-- first: end -->

            <!-- last: start -->
            <b-field :label="fields.lastname" v-if="prsn.persontype !== 'Team'">
              <b-input v-model="prsn.lastname"></b-input>
            </b-field>
            <!-- last: end -->

            <!-- one liner: start -->
            <b-field label="One Liner">
              <b-input v-model="prsn.oneliner"></b-input>
            </b-field>
            <!-- one liner: end-->

            <!-- occupation: start -->
            <b-field label="Occupation" v-if="prsn.persontype !== 'Team'">
              <b-input v-model="prsn.occupation"></b-input>
            </b-field>
            <!-- occupation: end -->

            <!-- dob: start -->
            <b-field label="Date of Birth" v-if="prsn.persontype !== 'Team'">
              <b-datepicker placeholder="Click to select..." v-model="prsn.dob" icon="calendar" >
                <button class="button is-primary is-small" @click="prsn.dob = new Date()" >
                  <b-icon icon="calendar-day"></b-icon>
                  <span>Today</span>
                </button>
                <button class="button is-danger is-small" @click="prsn.dob = null" >
                  <b-icon icon="times"></b-icon>
                  <span>Clear</span>
                </button>
              </b-datepicker>
            </b-field>
            <!-- dob: end -->

            <!-- gender: start -->
            <b-field label="Gender" v-if="prsn.persontype !== 'Team'">
              <b-select placeholder="Select..." expanded v-model="prsn.gender">
                <option v-for="sx in optSex" :value="sx.id" :key="sx.id">{{ sx.label }}</option>
              </b-select>
            </b-field>
            <!-- gender: end -->

            <div class="field mt11" v-if="isadmin"><b-switch v-model="prsn.demo">Demo</b-switch></div>
          </div>
          <!-- basic: end -->

          <!-- contacts: start-->
          <div style="margin-bottom: 20px;" v-if="naviedit.contact">
            <div style="height: 15px;"></div>

            <!-- contact Numbers: start-->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Contact Numbers</div>
              <div>
                <div class="iconbutton" @click="contactAdd()">
                  <font-awesome-icon :icon="['fal', 'plus-circle']" size="sm" ></font-awesome-icon>
                </div>
              </div>
            </div>

            <div v-for="(ct, index) in prsn.contactnumbers" :key="ct._id" class="dtable-row" >
              <div class="dtable-row-left">
                <b-select placeholder="Select..." v-model="ct.type">
                  <option v-for="option in optContactNo" :value="option.value" :key="option.value">{{ option.label }}</option>
                </b-select>
              </div>
              <div class="dtable-row-middle">
                <b-input v-model="ct.number"></b-input>
              </div>
              <div class="dtable-row-right">
                <div v-if="!ct.showDel">
                  <div class="iconbutton" @click="contactDelShow(true, index)">
                    <font-awesome-icon :icon="['fal', 'trash']" size="lg" ></font-awesome-icon>
                  </div>
                </div>
                <div v-if="ct.showDel" style="min-width: 55px; display: flex">
                  <div class="iconbutton" @click="contactDel(index)">
                    <font-awesome-icon :icon="['fal', 'check']" size="lg" ></font-awesome-icon>
                  </div>
                  <div class="iconbutton" @click="contactDelShow(false, index)">
                    <font-awesome-icon :icon="['fal', 'times']" size="lg" ></font-awesome-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="tsk lgreybg mv7" v-if="prsn.contactnumbers.length === 0" >
              No contact numbers added
            </div>
            <!-- contact Numbers: end -->

            <div style="height: 15px;"></div>

            <!-- emails: start -->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">E-mails</div>
              <div>
                <div class="iconbutton" @click="emailAdd()">
                  <font-awesome-icon :icon="['fal', 'plus-circle']" size="sm" ></font-awesome-icon>
                </div>
              </div>
            </div>

            <div v-for="(ct, index) in prsn.emails" :key="ct._id" class="dtable-row" >
              <div class="dtable-row-left">
                <b-select placeholder="Select..." v-model="ct.type" expanded>
                  <option v-for="option in optEmail" :value="option.value" :key="option.value" >{{ option.label }}</option>
                </b-select>
              </div>
              <div class="dtable-row-middle">
                <b-input v-model="ct.email"></b-input>
              </div>
              <div class="dtable-row-right">
                <div v-if="!ct.showDel">
                  <div class="iconbutton" @click="emailDelShow(true, index)">
                    <font-awesome-icon
                      :icon="['fal', 'trash']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                </div>
                <div v-if="ct.showDel" style="min-width: 55px; display: flex">
                  <div class="iconbutton" @click="emailDel(index)">
                    <font-awesome-icon
                      :icon="['fal', 'check']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                  <div class="iconbutton" @click="emailDelShow(false, index)">
                    <font-awesome-icon
                      :icon="['fal', 'times']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="tsk lgreybg mv7" v-if="prsn.emails.length === 0">
              No e-mail addresses added
            </div>
            <!-- emails: end -->

            <div style="height: 15px;"></div>

            <!-- addresses: start -->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Addresses</div>
              <div>
                <div class="iconbutton" @click="addressAdd()">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="sm"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>

            <div
              v-for="(ct, index) in prsn.addresses"
              :key="ct._id"
              class="dtable-row"
            >
              <div class="dtable-row-left">
                <b-select placeholder="Select..." v-model="ct.type" expanded>
                  <option
                    v-for="option in optEmail"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </b-select>
              </div>
              <div class="dtable-row-middle">
                <b-input
                  v-model="ct.address"
                  placeholder="Street"
                  style="margin-bottom: 2px;"
                ></b-input>
                <b-input
                  v-model="ct.town"
                  placeholder="Town"
                  style="margin-bottom: 2px;"
                ></b-input>
                <b-input
                  v-model="ct.county"
                  placeholder="County"
                  style="margin-bottom: 2px;"
                ></b-input>
                <b-input
                  v-model="ct.state"
                  placeholder="State"
                  style="margin-bottom: 2px;"
                ></b-input>
                <b-input
                  v-model="ct.postcode"
                  placeholder="Post code / Zip"
                  style="margin-bottom: 2px;"
                ></b-input>
                <b-input
                  v-model="ct.country"
                  placeholder="Country"
                  style="margin-bottom: 2px;"
                ></b-input>
              </div>
              <div class="dtable-row-right">
                <div v-if="!ct.showDel">
                  <div class="iconbutton" @click="addressDelShow(true, index)">
                    <font-awesome-icon
                      :icon="['fal', 'trash']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                </div>
                <div v-if="ct.showDel" style="min-width: 55px; display: flex">
                  <div class="iconbutton" @click="addressDel(index)">
                    <font-awesome-icon
                      :icon="['fal', 'check']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                  <div class="iconbutton" @click="addressDelShow(false, index)">
                    <font-awesome-icon
                      :icon="['fal', 'times']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="tsk lgreybg mv7" v-if="prsn.addresses.length === 0">
              No addresses available for this person
            </div>
            <!-- addresses: end -->
          </div>
          <!-- contacts: end-->

          <!-- profile: start -->
          <div style="margin-bottom: 20px;" v-if="naviedit.profile">
            <div style="height: 15px;"></div>

            <!-- image: start -->
            <div>
              <div class="isFlex isFlexJustify">
                <div class="isPointer" @click="changeImage()">
                  <img
                    src="/dummy/120x120.png"
                    class="thumb thumb-xlg thumb-circle"
                    v-if="prsn.photo === ''"
                  />
                  <img
                    :src="prsn.photo"
                    class="thumb thumb-xlg thumb-circle"
                    v-else
                  />
                </div>
              </div>
              <div class="p9" style="text-align: center;">
                Click on on image to change it.
              </div>
            </div>
            <!-- image: end -->

            <div style="height: 15px;"></div>

            <!-- profile: start -->
            <b-field label="More">
              <b-input type="textarea" v-model="prsn.profile"></b-input>
            </b-field>
            <!-- profile: end -->

            <div style="height: 15px;"></div>

            <!-- experience: start -->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Experience / Projects</div>
              <div>
                <div class="iconbutton" @click="experienceAdd('new', {}, -1)">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>

            <div
              class="dtable-row tsk vlgreybg mv7"
              v-for="(av, index) in prsn.employment"
              :key="av._id"
            >
              <div class="dtable-row-middle">
                <strong class="txth4">{{ av.project }}</strong>
                <span
                  ><br />From
                  <strong
                    >{{ av.datefrom | displaydate("Do MMM YYYY") }}
                  </strong>
                </span>
                <span v-if="av.duration === 'Active'">and still at it.</span>
                <span v-else
                  >To:
                  <strong
                    >{{ av.dateto | displaydate("Do MMMM YYYY") }}
                  </strong></span
                >
                <span><br />{{ av.description }}</span>
              </div>
              <div class="dtable-row-right">
                <div
                  class="iconbutton"
                  @click="experienceAdd('edit', av, index)"
                >
                  <font-awesome-icon
                    :icon="['fal', 'edit']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
                <div class="iconbutton" @click="experienceDelete(index)">
                  <font-awesome-icon
                    :icon="['fal', 'trash']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>

            <div class="tsk vlgreybg mv7" v-if="prsn.employment.length === 0">
              No experience added
            </div>
            <!-- experience: end -->
          </div>
          <!-- profile: end -->

          <!-- connections: start -->
          <div style="margin-bottom: 20px;" v-if="naviedit.connections">
            <div style="height: 15px;"></div>

            <!-- contact type: start -->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Contact Type</div>
            </div>
            <div class="field" style="margin: 5px;">
              <b-checkbox v-model="prsn.personrole.contact">Contact</b-checkbox>
              <b-checkbox v-model="prsn.personrole.prospect"
                >Prospect</b-checkbox
              >
              <b-checkbox v-model="prsn.personrole.client">Client</b-checkbox>
              <b-checkbox v-model="prsn.personrole.supplier"
                >Supplier</b-checkbox
              >
              <b-checkbox v-model="prsn.personrole.friend">Friend</b-checkbox>
            </div>
            <!-- contact type: end -->

            <div style="height: 15px;"></div>

            <!-- availableto: start -->
            <!-- <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Availble to team(s)</div>
              <div>
                <div class="iconbutton" @click="selectPerson()">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div class="dtable-row tsk lgreybg mv7" v-for="(av, index) in prsn.availableto" :key="av._id" >
              <div class="dtable-row-middle">
                <strong>{{ av.firstname }}</strong>
              </div>
              <div class="dtable-row-right">
                <div class="iconbutton" @click="availabletoDelete(index)">
                  <font-awesome-icon :icon="['fal', 'trash']" size="lg" ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div class="tsk lgreybg mv7" v-if="prsn.availableto.length === 0">
              Only available to me
            </div> -->
            <!-- availableto: ends -->

            <div style="height: 15px;"></div>

            <!-- Associated: start -->
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Associated with</div>
              <div>
                <div class="iconbutton" @click="selectAssociation()">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div
              class="dtable-row tsk lgreybg mv7"
              v-for="(av, index) in prsn.inco"
              :key="av._id"
            >
              <div class="dtable-row-middle">
                <strong>{{ av.firstname }}</strong> <br />{{ av.how }}
              </div>
              <div class="dtable-row-right">
                <div class="iconbutton" @click="associatedDelete(index)">
                  <font-awesome-icon
                    :icon="['fal', 'trash']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div class="tsk lgreybg mv7" v-if="prsn.inco.length === 0">
              No associations setup.
            </div>
            <!-- Associated: ends -->
          </div>
          <!-- connections: end -->

          <!-- Custom Fields: start -->
          <div style="margin-bottom: 20px;" v-if="naviedit.custom">
            <div style="height: 15px;"></div>

            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Custom Fields</div>
              <div>
                <div class="iconbutton" @click="infoAdd()">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div
              v-for="(ct, index) in prsn.info"
              :key="ct._id"
              class="dtable-row"
            >
              <div class="dtable-row-left">
                <b-input
                  v-model="ct.key"
                  placeholder="Key"
                  style="margin-bottom: 2px;"
                ></b-input>
              </div>
              <div class="dtable-row-middle">
                <b-input
                  v-model="ct.value"
                  placeholder="Value"
                  style="margin-bottom: 2px;"
                ></b-input>
              </div>
              <div class="dtable-row-right">
                <div v-if="!ct.showDel">
                  <div class="iconbutton" @click="infoDelShow(true, index)">
                    <font-awesome-icon
                      :icon="['fal', 'trash']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                </div>
                <div v-if="ct.showDel" style="min-width: 55px; display: flex">
                  <div class="iconbutton" @click="infoDel(index)">
                    <font-awesome-icon
                      :icon="['fal', 'check']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                  <div class="iconbutton" @click="infoDelShow(false, index)">
                    <font-awesome-icon
                      :icon="['fal', 'times']"
                      size="lg"
                    ></font-awesome-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Custom Fields: end -->

          <!-- schedule: start -->
          <div style="margin-bottom: 20px;" v-if="naviedit.schedule">
            <div class="isFlex isFlexAlign">
              <div class="isFlexGrow txth3">Schedule</div>
              <div>
                <div class="iconbutton" @click="scheduleAdd('new', {}, -1)">
                  <font-awesome-icon
                    :icon="['fal', 'plus-circle']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>

            <div
              class="dtable-row tsk vlgreybg mv7"
              v-for="(av, index) in prsn.schedule"
              :key="index"
            >
              <div class="dtable-row-middle">
                <strong class="txth4">{{ av.day }}</strong>
                <span v-if="av.grind === 'settime'">
                  <span
                    ><br />From
                    <strong
                      >{{ av.timestart.hour }}:{{ av.timestart.minutes }}
                    </strong>
                  </span>
                  <span
                    >To
                    <strong
                      >{{ av.timeend.hour }}:{{ av.timeend.minutes }}
                    </strong>
                  </span>
                </span>
                <span v-else
                  ><br />Duration:
                  <strong
                    >{{ av.duration.hour }}:{{ av.duration.minutes }}
                  </strong>
                  Blocks: <strong>{{ av.duration.blocks }} </strong></span
                >
              </div>
              <div class="dtable-row-right">
                <div class="iconbutton" @click="scheduleAdd('edit', av, index)">
                  <font-awesome-icon
                    :icon="['fal', 'edit']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
                <div class="iconbutton" @click="scheduleDelete(index)">
                  <font-awesome-icon
                    :icon="['fal', 'trash']"
                    size="lg"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <div class="tsk vlgreybg mv7" v-if="prsn.schedule.length === 0">
              No schedule items setup.
            </div>
          </div>
          <!-- schedule: end -->

          <div style="height: 100px;"></div>
        </div>
      </div>
      <!-- <div class="contentright-body-footer">Right Foot</div> -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ObjectID from "bson-objectid";
import { Utils } from "../mixins/utils.js";
import debounce from "lodash/debounce";
import store from "../store";
import { EventBus } from "../event-bus.js";
import { dbFunctions } from "../mixins/dbMixins.js";
import ModalInvite from "@/components/ModalInvite.vue";
import ModalPeopleList from "@/components/ModalPeopleList.vue";
import ModalForm from "@/components/ModalForm.vue";
import ModalImage from "@/components/ModalImage.vue";
import ModalEditor from "@/components/ModalEditor.vue";
// import { start } from 'repl';
export default {
  mixins: [dbFunctions, Utils],
  name: "Person",
  store,
  props: {
    inperson: Object
  },

  components: {
    ModalInvite
  },

  watch: {
    inperson: function(newVal, oldVal) {
      // watch it
      this.editAllow = false;
      this.editAllowTeam = false;
      this.switchMode("view");
      this.changeViewView("basic");
      this.prsn = newVal;
      this.fields.showTeam = false;
      this.prsn.inteam = [];
      this.wlRows = [];
      this.loadPerson();
      this.notesReload();
    }
  },

  data() {
    return {
      prsn: this.inperson,
      nts: [],
      user: store.state.user,
      personTypeIcon: "user-circle",
      GoalList: [],
      mode: {
        view: true,
        edit: false
      },
      showDelete: false,
      editAllow: true,
      editAllowTeam: false,
      naviview: {
        basic: true,
        address: false,
        schedule: false,
        log: false,
        projects: false,
        tasks: false,
        notes: false,
        connections: false,
        custom: false
      },
      naviedit: {
        basic: true,
        contact: false,
        profile: false,
        experience: false,
        connections: false,
        custom: false,
        schedule: false
      },
      fields: {
        firstname: "First Name",
        lastname: "Last Name",
        showTeam: false
      },
      modalA: {},
      // notes: start
      notesSearchField: "",
      notesLimit: 25,
      notesSkip: 0,
      noteActive: {},
      noteMethod: "new",
      nodeId: -1,
      // notes: end

      TeamLive: localStorage.getItem("TeamLive"),
      TeamLiveName: localStorage.getItem("TeamLiveName"),
      // worklog: start
      wlHasOpen: false,
      wlIsOpen: false,
      wlActive: null,
      wlRows: [],
      wlmonth: new Date(),
      wlFileName: "timelog.csv",
      wlTeamList: [],
      wlTeam: { _id: "all", firstname: "All Teams" },
      wlTeamMembers: [],
      wlTeamMember: {
        _id: '',
        firstname: ''
      },
      wlSession: {},
      wlOpenSession: {},

      wlMLshow: false,
      wlStartDate: null,
      wlStartTime: null,
      wlEndDate: null,
      wlEndTime: null,
      wlMLwhat: '',
      wlMLwhy: '',
      wlSstatus: 'new',
      wlSperson: '',
      // worklog: end
      isDemo: false,
      sub: localStorage.getItem("sub"),
      isadmin: localStorage.getItem("isadmin"),
    };
  },

  async created() {
    EventBus.$on("Select_Person", data => {
      this.availableTo(data);
    });
    EventBus.$on("Associate_Person", data => {
      this.associatePre(data);
    });
    EventBus.$on("from_experience", data => {
      this.experienceAddDo(data);
    });
    EventBus.$on("set_avatar", data => {
      this.prsn.photo = data.image;
      this.modalI.close();
      this.personSave();
    });
    EventBus.$on("from_schedule", data => {
      this.scheduleAddDo(data);
    });
    EventBus.$on("from_note", data => {
      this.noteAddDo(data);
    });
    EventBus.$on("bus_wl", data => {
      this.wlEditLogDo(data);
    });
    // this.GoalList = await this.getGoals()
    this.getGoals();
    this.loadPerson(); // Select_Person
    this.notesGet();
  },

  methods: {
    switchMode(mode) {
      this.mode.view = false;
      this.mode.edit = false;
      this.mode[mode] = true;
    },

    changeViewView(area) {
      for (let f in this.naviview) {
        this.naviview[f] = false;
      }
      this.naviview[area] = true;
    },

    changeViewEdit(area) {
      for (let f in this.naviedit) {
        this.naviedit[f] = false;
      }
      this.naviedit[area] = true;
    },

    matchGoal(id) {
      for (const gl of this.GoalList) {
        if (gl._id === id) {
          return gl.goaltitle;
        }
      }
    },

    async loadPerson() {
      let forceSaveOnLoad = false;
      const prsnin = await this.getData(
        "people",
        { _id: this.prsn._id },
        {},
        "peoplelist",
        "none",
        "searchextended"
      );
      if (prsnin.length === 0) {
        this.$buefy.snackbar.open({
          message:
            "An error has occured. This could be due to no network connection, a delay in adding a recorde or the file being previously deleted. Please try again.",
          position: "is-top"
        });
        return;
      }
      this.prsn = prsnin[0];
      // check to see if can edit team
      const sub = store.state.user.sub;

      this.isDemo = this.prsn.demo;
      if (this.prsn.sub === this.sub) {
        this.isDemo = false;
      }

      if (this.prsn.sub !== this.sub) {
        if (this.prsn.inteam && this.prsn.inteam.length === 0) {
          this.editAllowTeam = false;
        }
        if (this.prsn.inteam && this.prsn.inteam.length !== 0) {
          this.editAllowTeam = false;
          for (const inrow of this.prsn.inteam) {
            if (inrow.userid === sub) {
              if (inrow.peopleadmin) {
                this.editAllowTeam = true;
              }
            }
          }
        }
      } else {
        this.editAllow = true;
        this.editAllowTeam = true;
      }

      switch (this.prsn.persontype) {
        case "Organisation":
          this.personTypeIcon = "building";
          this.fields.firstname = "Name";
          this.fields.lastname = "T/A";
          this.fields.showTeam = false;
          break;

        case "Team":
          this.personTypeIcon = "users";
          this.fields.firstname = "Team Name";
          this.fields.showTeam = true;
          // correct in team
          if (this.user.sub === this.prsn.sub) {
            let hasSelf = false;
            for (const itm of this.prsn.inteam) {
              if (itm.userid === this.prsn.sub) {
                hasSelf = true;
              }
            }

            if (!hasSelf) {
              const newtm = { ...this.teammember };
              newtm.firstname = this.user.subname;
              newtm.email = this.user.email;
              newtm.userid = this.user.sub;
              newtm.status = "accepted";
              newtm.peopleadmin = true;
              newtm.projectadmin = true;
              newtm.tasksadd = true;
              newtm.tasksedit = true;
              newtm.taskscomplete = true;
              newtm.tasksarchive = true;
              newtm.tasksdelete = true;
              newtm.tasksassign = true;
              newtm.owner = true;
              this.prsn.inteam.unshift(newtm);
              forceSaveOnLoad = true;
            }
          }

          break;
        default:
          this.personTypeIcon = "user-circle";
          this.fields.firstname = "First Name";
          this.fields.lastname = "Last Name";
          this.fields.showTeam = false;
          break;
      }
      if (this.prsn.dob === "") {
        this.prsn.dob = null;
      } else {
        this.prsn.dob = new Date(this.prsn.dob);
      }
      if (this.prsn.goalid !== "") {
        this.prsn.goalname = this.matchGoal(this.prsn.goalid);
      }
      this.wlTeamList = [];
      this.wlTeamList.push({ _id: "all", firstname: "All Teams" });
      for (const av of this.prsn.availableto) {
        this.wlTeamList.push(av);
      }
      if (forceSaveOnLoad) {
        this.personSave();
      }
      this.wlChangeTeam();
      this.wlLoad();
    },

    contactAdd() {
      const id = new ObjectID();
      const idout = id.str;
      this.prsn.contactnumbers.push({ _id: idout, type: "Mobile", number: "" });
    },
    contactDelShow(mode, index) {
      Vue.set(this.prsn.contactnumbers[index], "showDel", mode);
    },
    contactDel(index) {
      this.prsn.contactnumbers.splice(index, 1);
    },
    emailAdd() {
      const id = new ObjectID();
      const idout = id.str;
      this.prsn.emails.push({ _id: idout, type: "Work", email: "" });
    },
    emailDelShow(mode, index) {
      Vue.set(this.prsn.emails[index], "showDel", mode);
    },
    emailDel(index) {
      this.prsn.emails.splice(index, 1);
    },
    addressAdd() {
      const id = new ObjectID();
      const idout = id.str;
      this.prsn.addresses.push({
        _id: idout,
        type: "Work",
        address: "",
        town: "",
        county: "",
        state: "",
        country: ""
      });
    },
    addressDelShow(mode, index) {
      Vue.set(this.prsn.addresses[index], "showDel", mode);
    },
    addressDel(index) {
      this.prsn.addresses.splice(index, 1);
    },
    infoAdd() {
      const id = new ObjectID();
      const idout = id.str;
      this.prsn.info.push({ _id: idout, key: "", value: "" });
    },
    infoDelShow(mode, index) {
      Vue.set(this.prsn.info[index], "showDel", mode);
    },
    infoDel(index) {
      this.prsn.info.splice(index, 1);
    },

    // team: start
    openInvite(mode, row, useIndex) {
      const that = this;
      this.$buefy.modal.open({
        parent: this,
        component: ModalInvite,
        hasModalCard: true,
        props: {
          mode,
          useIndex,
          prsn: that.prsn
        }
      });
    },

    deleteTeamMember(index) {
      this.$buefy.dialog.confirm({
        title: "Delete Team Member",
        message:
          "Are you sure you want to <b>delete</b> this team member? This action cannot be undone.",
        confirmText: "Delete Member",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.prsn.inteam.splice(index, 1);
          this.$buefy.toast.open("Memeber deleted!");
          this.updata("people", this.prsn, "person_saved", {}, false);
        }
      });
    },

    // availableto:start
    selectPerson(mode, row, useIndex) {
      const that = this;
      this.modalA = this.$buefy.modal.open({
        parent: this,
        component: ModalPeopleList,
        hasModalCard: true,
        props: {
          showOpen: false,
          showSelect: true,
          showCheckBox: false,
          hideHeader: true,
          hideRefresh: true,
          hideFilter: true,
          hideAdd: true,
          hideFooter: true,
          listPersonType: "Team",
          listClients: false,
          listSuppliers: false,
          listProspects: false,
          listContacts: false,
          listFriends: false,
          callBus: "Select_Person",
          prsn: that.prsn
        }
      });
    },

    availableTo(row) {
      const newrow = { _id: row._id, firstname: row.firstname };
      this.prsn.availableto.push(newrow);
      this.personSave();
      this.modalA.close();
    },

    availabletoDelete(index) {
      this.$buefy.dialog.confirm({
        title: "Remove team access",
        message:
          "Are you sure you want to <b>remove access</b> this team? This action cannot be undone.",
        confirmText: "Remove Team",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.prsn.availableto.splice(index, 1);
          this.$buefy.toast.open("Team Removed!");
          this.updata("people", this.prsn, "none", {}, false);
        }
      });
    },
    // availableto:end
    // team: end

    // associated:start
    selectAssociation(mode, row, useIndex) {
      const that = this;
      this.modalA = this.$buefy.modal.open({
        parent: this,
        component: ModalPeopleList,
        hasModalCard: true,
        props: {
          showOpen: false,
          showSelect: true,
          showCheckBox: false,
          hideHeader: true,
          hideRefresh: true,
          hideFilter: true,
          hideAdd: true,
          hideFooter: true,
          listPersonType: "",
          listClients: false,
          listSuppliers: false,
          listProspects: false,
          listContacts: false,
          listFriends: false,
          callBus: "Associate_Person",
          prsn: that.prsn
        }
      });
    },

    associatePre(row) {
      this.$buefy.dialog.prompt({
        message: `What is the association`,
        confirmText: "Proceed",
        inputAttrs: {
          placeholder: "eg. Staff, Partner"
        },
        onConfirm: value => {
          this.associateDo(row, value);

          // this.$buefy.toast.open(`Your name is: ${value}`)
        }
      });
    },

    associateDo(row, how) {
      const newrow = { _id: row._id, firstname: row.firstname, how };
      this.prsn.inco.push(newrow);
      this.personSave();
      this.modalA.close();
    },

    associatedDelete(index) {
      this.$buefy.dialog.confirm({
        title: "Un-associate contact",
        message:
          "Are you sure you want to <b>remove</b> this association? This action cannot be undone.",
        confirmText: "Remove Association",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.prsn.inco.splice(index, 1);
          this.$buefy.toast.open("Association Removed!");
          this.updata("people", this.prsn, "none", {}, false);
        }
      });
    },
    // associated:end

    // experience:start
    experienceAdd(mode, data, index) {
      let fields = [
        {
          type: "input",
          subtype: "text",
          field: "project",
          label: "Co / Project Name",
          value: "",
          placeholder: "eg. ACME Trade, AQUA CMS",
          required: "true",
          show: true
        },
        {
          type: "input",
          subtype: "text",
          field: "role",
          label: "Role",
          value: "",
          placeholder: "eg. Founder, CIO, Team Leader",
          required: "true",
          show: true
        },
        {
          type: "input",
          subtype: "textarea",
          field: "description",
          label: "What contribution did you make?",
          value: "",
          placeholder: "Describe what benefits you contributed.",
          show: true
        },
        {
          type: "date",
          field: "datefrom",
          label: "When did you start?",
          value: null,
          show: true
        },
        {
          type: "select",
          field: "duration",
          label: "What is the state of the project?",
          value: "Completed",
          options: [
            { label: "Completed", value: "Completed" },
            { label: "Active", value: "Active" }
          ],
          show: true
        },
        {
          type: "date",
          field: "dateto",
          label: "If completed, when did you end?",
          value: null,
          show: true
        }
      ];
      if (mode === "edit") {
        fields = this.assignValueToFields(fields, data);
      }
      this.modalF = this.$buefy.modal.open({
        parent: this,
        component: ModalForm,
        hasModalCard: true,
        props: {
          fields,
          callBus: "from_experience",
          busData: {
            index,
            mode
          }
        }
      });
    },

    experienceAddDo(inbound) {
      if (inbound.mode === "new") {
        let newrow = {
          project: "",
          description: "",
          datefrom: null,
          duration: "",
          dateto: null
        };
        const indata = inbound.fields;
        newrow = this.assignFieldsToValue(indata, newrow);
        if (!this.prsn.employment) {
          this.prsn.employment = [];
        }
        this.prsn.employment.push(newrow);
      } else {
        let newrow = this.prsn.employment[inbound.index];
        const indata = inbound.fields;
        newrow = this.assignFieldsToValue(indata, newrow);
      }
      this.modalF.close();
      this.personSave();
    },

    experienceDelete(index) {
      this.$buefy.dialog.confirm({
        title: "Un-associate contact",
        message:
          "Are you sure you want to <b>remove</b> this experience? This action cannot be undone.",
        confirmText: "Remove Experience",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.prsn.employment.splice(index, 1);
          this.$buefy.toast.open("Experience Removed!");
          this.updata("people", this.prsn, "none", {}, false);
        }
      });
    },

    // experience:end

    // image:start
    changeImage() {
      this.modalI = this.$buefy.modal.open({
        parent: this,
        component: ModalImage,
        hasModalCard: true,
        props: {
          callBus: "set_avatar",
          busData: {}
        }
      });
    },
    // image:end

    closeRight() {
      EventBus.$emit("close_right", this.prsn);
    },

    async personSave() {
      this.updata("people", this.prsn, "person_saved", {}, false);
    },

    deleteShow() {
      this.showDelete = true;
    },

    deleteHide() {
      this.showDelete = false;
    },

    deleteDo() {
      this.prsn.deleted = true;
      this.updata("people", this.prsn, "person_deleted", {}, false);
      this.deleteHide();
      // EventBus.$emit("delete", this.prsn);
    },

    // schedule: start

    scheduleAdd(mode, data, index) {
      let fields = [
        {
          type: "select",
          field: "day",
          label: "Please select a day",
          value: "Monday",
          show: true,
          options: [
            { label: "Monday", value: "Monday" },
            { label: "Tuesday", value: "Tuesday" },
            { label: "Wednesday", value: "Wednesday" },
            { label: "Thursday", value: "Thursday" },
            { label: "Friday", value: "Friday" },
            { label: "Saturday", value: "Saturday" },
            { label: "Sunday", value: "Sunday" }
          ]
        },
        {
          type: "select",
          field: "grind",
          label: "Daily allocation type",
          value: "settime",
          show: true,
          options: [
            { label: "A set time on the day", value: "settime" },
            { label: "Any time of day for a length of time", value: "opentime" }
          ]
        },
        {
          type: "time",
          field: "timestart",
          label: "Start Time",
          hour: "9",
          minutes: "15",
          timefull: 915,
          show: true,
          conditionkey: 1,
          conditionvalue: "settime"
        },
        {
          type: "time",
          field: "timeend",
          label: "End Time",
          hour: "10",
          minutes: "15",
          timefull: 1015,
          show: true,
          conditionkey: 1,
          conditionvalue: "settime"
        },
        {
          type: "duration",
          field: "duration",
          label: "How long do you want to spend on this client each day?",
          hour: "1",
          minutes: "00",
          blocks: 4,
          show: false,
          conditionkey: 1,
          conditionvalue: "opentime"
        }
      ];

      if (mode === "edit") {
        fields = this.assignValueToFields(fields, data);
      }

      this.modalS = this.$buefy.modal.open({
        parent: this,
        component: ModalForm,
        hasModalCard: true,
        props: {
          fields,
          callBus: "from_schedule",
          busData: {
            index,
            mode
          }
        }
      });
    },

    scheduleAddDo(inbound) {
      if (inbound.mode === "new") {
        let newrow = {
          day: "",
          grind: "",
          timestart: { hour: 0, minutes: 0, timefull: 0 },
          timeend: { hour: 0, minutes: 0, timefull: 0 },
          duration: { hour: 0, minutes: 0, blocks: 1 }
        };
        const indata = inbound.fields;
        newrow = this.assignFieldsToValue(indata, newrow);
        if (!this.prsn.schedule) {
          this.prsn.schedule = [];
        }
        this.prsn.schedule.push(newrow);
      } else {
        let newrow = this.prsn.schedule[inbound.index];
        const indata = inbound.fields;
        newrow = this.assignFieldsToValue(indata, newrow);
      }
      this.modalS.close();
      this.personSave();
    },

    scheduleDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>remove</b> this schedule item? This action cannot be undone.",
        confirmText: "Remove Schedule Item",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.prsn.schedule.splice(index, 1);
          this.$buefy.toast.open("Schedule Item Removed!");
          this.updata("people", this.prsn, "none", {}, false);
        }
      });
    },
    // schedule: end

    // notes: start
    notesLoadNext() {
      this.notesSkip += this.notesLimit;
      this.notesGet();
    },

    notesReload() {
      this.notesSkip = 0;
      this.nts = [];
      this.notesGet();
    },

    notesSearch: debounce(function() {
      this.notesReload();
    }, 2000),

    async notesGet() {
      let query = { personid: this.prsn._id, deleted: false };
      if (this.notesSearchField !== "") {
        query = {
          $and: [
            { deleted: false },
            { personid: this.prsn._id },
            { $text: { $search: this.notesSearchField } }
          ]
        };
      }
      const ntsin = await this.getData(
        "notes",
        query,
        { limit: this.notesLimit, skip: this.notesSkip },
        "none",
        "none",
        "searchextended"
      );
      if (ntsin.length === 0) {
        // this.$buefy.toast.open("No more records");
        return;
      }
      for (const nt of ntsin) {
        const ntprtxt = nt.notehtml.replace(/<[^>]*>?/gm, "");
        const pretxt = ntprtxt.substring(0, 150);
        nt.notepre = pretxt;
        nt.expand = false;
        this.nts.push(nt);
      }
      // this.nts = ntsin;
    },

    noteExpand(row, index) {
      if (this.nts[index].expand) {
        row.expand = false;
      } else {
        row.expand = true;
      }
    },

    noteAdd(method, row, id) {
      this.noteMethod = method;
      let usetext = "";
      if (method === "new") {
        this.noteActive = { ...this.note };
        const id = new ObjectID();
        const idout = id.str;
        this.noteActive._id = idout;
        const insub = this.GetMeLocal();
        this.noteActive.sub = insub.sub;
        this.noteActive.subname = insub.subname;
        // this.noteActive.availableto = this.prsn.availableto;
        this.noteActive.personid = this.prsn._id;
        this.noteActive.person = this.prsn.firstname + " " + this.prsn.lastname;
        this.noteActive.notedt = this.getTS();
        this.noteActive.nodeId = -1;
        this.noteActive.notepre = "";
      } else {
        this.noteActive = row;
        usetext = row.notehtml;
        this.nodeId = id;
      }

      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) {
        useFull = false;
        useCanCancel = true;
      }

      this.modalN = this.$buefy.modal.open({
        parent: this,
        component: ModalEditor,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          area: 'peoplenotes',
          usetext,
        },
        events: {
          peopleNoteChanged: value => {
            this.noteAddDo(value);
          }
        }
      });
    },
    noteAddDo(data) {
      this.noteActive.notehtml = data.html;
      this.noteActive.notetext = data.json;
      const ntprtxt = data.html.replace(/<[^>]*>?/gm, "");
      const pretxt = ntprtxt.substring(0, 150);
      this.noteActive.notepre = pretxt;
      this.updata("notes", this.noteActive, "none", {}, false);
      this.$buefy.toast.open("Note saved");
      if (this.noteMethod === "new") {
        this.nts.unshift(this.noteActive);
        this.noteMethod = "edit";
      }
    },

    async noteDelete(row, index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>delete</b> this note? This action cannot be undone.",
        confirmText: "Delete Note",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          row.deleted = true;
          const upnte = await this.livePostPromise("upsert", "notes", row);
          this.nts.splice(index, 1);
          this.$buefy.toast.open("Note Removed!");
        }
      });
    },
    // notes: end

    // worklog: start
    wlShowMonth() {
      this.wlRows = [];
      this.wlLoad();
    },

    async wlLoad() {
      this.wlRows = [];
      const indate = this.getDateFull(this.wlmonth, "none", "none", 0);

      const data = {
        personid: this.prsn._id,
        endmonth: indate.monthsingle,
        endyear: indate.year,
        // isLeader: this.isLeader,
      }
      // if (this.wlTeamMember._id !== "all") {
      //   query.$and.push({ sub: this.wlTeamMember._id });
      // }
      const options = {};
      const getses = await this.DataSend('post', '/worklog/person', data, '', false);
      console.log('getses', getses);
      if (getses.logs !== null) {
        this.wlActive = getses.logs;
        this.wlHasOpen = true;
        if ( this.wlActive.personid === this.prsn._id ) {
          const getwl = await this.DataGet(`/workload/${this.wlActive._id}`);
          this.wlSession = getwl;
          this.wlIsOpen = true;
        }
      }
      for (const ss of getses.rows) {
        if (ss.sub === this.sub) {
          if (ss.open) {
            this.wlHasOpen = true;
          }
        }
        ss.selected = false;
        // check if can edit
        ss.canedit = false;
        if (ss.sub === this.sub) {
          ss.canedit = true;
        }
        this.wlRows.push(ss);
      }
    },

    async wlStart() {
      console.log('start tracking');
      const inMe = this.GetMeLocal();
      if (this.wlHasOpen) {
        this.$buefy.snackbar.open({
          message: "You already have an open session. Please close it first.",
          position: "is-top"
        });
        return;
      }
      const TeamLive = localStorage.getItem("TeamLive");
      const TeamLiveName = localStorage.getItem("TeamLiveName");
      const newWL = { ...this.worklog };
      const id = new ObjectID();
      const idout = id.str;
      newWL._id = idout;
      newWL.sub = inMe.sub;
      newWL.subname = inMe.subname;
      newWL.availableto = this.prsn.availableto;
      newWL.personid = this.prsn._id;
      newWL.person = this.prsn.firstname + " " + this.prsn.lastname;

      const indate = this.getDateFull(new Date(), "none", "none", 0);
      newWL.start = indate.ts;
      newWL.startdt.day = indate.daysingle;
      newWL.startdt.dayofweek = indate.dayofweek;
      newWL.startdt.dayst = indate.dayst;
      newWL.startdt.daytxt = indate.dayofweektxtshort;
      newWL.startdt.hour = indate.hoursingle;
      newWL.startdt.week = indate.week;
      newWL.startdt.minute = indate.minutesingle;
      newWL.startdt.month = indate.monthsingle;
      newWL.startdt.monthtxt = indate.monthshort;
      newWL.startdt.quarter = indate.quarter;
      newWL.startdt.year = indate.year;

      const newlog = await this.updata( "worklog", newWL, "none", {}, false);
      const activelog = {
        _id: idout,
        personid: this.prsn._id,
        person: this.prsn.firstname + " " + this.prsn.lastname,
      }
      EventBus.$emit('has_active_task', activelog);
      this.wlSession = newlog;
      this.wlIsOpen = true;

      // this.wlRows = [];
      // this.wlLoad();
    },

    async wlStop() {
      const row = this.wlSession;
      // if (row.description === "") {
      //   this.$buefy.snackbar.open({
      //     message:
      //       "Come on, enter what you did. A minute or so spent now will save days trying to get your hours approved.",
      //     position: "is-top",
      //     duration: 7000
      //   });
      //   return;
      // }
      const indate = this.getDateFull(new Date(), "none", "none", 0);
      row.end = indate.ts;
      row.enddt.day = indate.daysingle;
      row.enddt.dayofweek = indate.dayofweek;
      row.enddt.dayst = indate.dayst;
      row.enddt.daytxt = indate.dayofweektxtshort;
      row.enddt.hour = indate.hoursingle;
      row.enddt.week = indate.week;
      row.enddt.minute = indate.minutesingle;
      row.enddt.month = indate.monthsingle;
      row.enddt.monthtxt = indate.monthshort;
      row.enddt.quarter = indate.quarter;
      row.enddt.year = indate.year;
      row.open = false;

      // calculate the totals
      const tsecs = row.end - row.start;
      row.totaltime = tsecs;
      const insecs = this.secondsToHours(tsecs);
      row.totalhours = insecs.hours;
      row.totalminutes = insecs.minutes;
      row.totalseconds = insecs.seconds;

      const updl = await this.DataSend('put', '/worklog/update', row);
      EventBus.$emit('has_active_task', null);
      this.wlLoad();
      this.wlIsOpen = false;
    },

    wlEditLog(mode, row, index) {
      const fields = [
        {
          type: "input",
          subtype: "textarea",
          field: "description",
          label: "What did you do?",
          value: "",
          placeholder: "I did the following...",
          required: "true",
          show: true
        },
        {
          type: "date",
          field: "startdate",
          label: "Start date",
          value: new Date(),
          show: true,
          inline: true
        },
        {
          type: "time",
          field: "starttime",
          label: "Start Time",
          hour: "9",
          minutes: "15",
          timefull: 915,
          show: true
        },
        {
          type: "date",
          field: "enddate",
          label: "End date",
          value: new Date(),
          show: true,
          inline: true
        },
        {
          type: "time",
          field: "endtime",
          label: "End Time",
          hour: "17",
          minutes: "15",
          timefull: 915,
          show: true
        },
        {
          type: "input",
          subtype: "textarea",
          field: "islatereason",
          label: "Reason for not using the timer?",
          value: "",
          placeholder: "Why?",
          required: "true",
          show: true
        },
        {
          type: "select",
          field: "islatestatus",
          label: "Approval Status",
          value: "new",
          options: [
            { label: "new", value: "new" },
            { label: "rejected", value: "rejected" },
            { label: "approved", value: "approved" }
          ],
          show: true,
          disabled: true
        },
        {
          type: "input",
          subtype: "textarea",
          field: "islatestatusmessage",
          label: "Approval message",
          value: "",
          placeholder: "",
          show: true,
          disabled: true
        }
      ];
      if (mode === "edit") {
        this.wlOpenSession = row;
        fields[0].value = row.description;
        const stdt = new Date(
          row.startdt.year,
          row.startdt.month - 1,
          row.startdt.day,
          row.startdt.hour,
          row.startdt.minute,
          0,
          0
        );
        fields[1].value = stdt;
        fields[2].hour = row.startdt.hour;
        fields[2].minutes = row.startdt.minute;
        const endt = new Date(
          row.enddt.year,
          row.enddt.month - 1,
          row.enddt.day,
          row.enddt.hour,
          row.enddt.minute,
          0,
          0
        );
        fields[3].value = endt;
        fields[4].hour = row.enddt.hour;
        fields[4].minutes = row.enddt.minute;
        fields[5].value = row.islatereason;
        fields[6].value = row.islatestatus;
        fields[7].value = row.islatestatusmessage;
        if (this.editAllow) {
          fields[6].disabled = false;
          fields[7].disabled = false;
        }
      }
      this.modalWL = this.$buefy.modal.open({
        parent: this,
        component: ModalForm,
        hasModalCard: true,
        props: {
          fields,
          callBus: "bus_wl",
          busData: {
            index,
            mode
          }
        }
      });
    },

    async wlMLDo() {
      if (this.wlStartDate === null) { this.$buefy.snackbar.open({ message: "Please select a start date", position: "is-top" }); return; }
      if (this.wlStartTime === null) { this.$buefy.snackbar.open({ message: "Please select a start time", position: "is-top" }); return; }
      if (this.wlEndDate === null) { this.$buefy.snackbar.open({ message: "Please select a end date", position: "is-top" }); return; }
      if (this.wlEndTime === null) { this.$buefy.snackbar.open({ message: "Please select a end time", position: "is-top" }); return; }
      if (this.wlMLwhy === '') { this.$buefy.snackbar.open({ message: "Please say why you are doing a manual entry?", position: "is-top" }); return; }

      let newWL = {};
      newWL = { ...this.worklog };
      const id = new ObjectID();
      const idout = id.str;

      const meIn = this.GetMeLocal();

      newWL.sub = meIn.sub;
      newWL.subname = meIn.subname;
      newWL.personid = this.prsn._id;
      newWL.person = this.prsn.firstname + " " + this.prsn.lastname;

      newWL.description = this.wlMLwhat;
      newWL.islatereason = this.wlMLwhy;

      const inst = this.getDateFull(this.wlStartDate, "none", "none", 0);
      const instspre = this.getDateFull(this.wlStartTime, "none", "none", 0);
      const newst = new Date(inst.year, inst.monthsingle-1, inst.daysingle, instspre.hoursingle, instspre.minutesingle, 0, 0);
      const insts = this.getDateFull(newst, "none", "none", 0);
      newWL.start = insts.ts;
      newWL.startdt.day = insts.daysingle;
      newWL.startdt.dayofweek = insts.dayofweek;
      newWL.startdt.dayst = insts.dayst;
      newWL.startdt.daytxt = insts.dayofweektxtshort;
      newWL.startdt.week = insts.week;
      newWL.startdt.month = insts.monthsingle;
      newWL.startdt.monthtxt = insts.monthshort;
      newWL.startdt.quarter = insts.quarter;
      newWL.startdt.year = insts.year;
      newWL.startdt.hour = insts.hoursingle;
      newWL.startdt.minute = insts.minutesingle;

      const endt = this.getDateFull(this.wlEndDate, "none", "none", 0);
      const endtpre = this.getDateFull(this.wlEndTime, "none", "none", 0);
      const newet = new Date(endt.year, endt.monthsingle-1, endt.daysingle, endtpre.hoursingle, endtpre.minutesingle, 0, 0);
      const endtt = this.getDateFull(newet, "none", "none", 0);
      newWL.end = endtt.ts;
      newWL.enddt.day = endtt.daysingle;
      newWL.enddt.dayofweek = endtt.dayofweek;
      newWL.enddt.dayst = endtt.dayst;
      newWL.enddt.daytxt = endtt.dayofweektxtshort;
      newWL.enddt.week = endtt.week;
      newWL.enddt.month = endtt.monthsingle;
      newWL.enddt.monthtxt = endtt.monthshort;
      newWL.enddt.quarter = endtt.quarter;
      newWL.enddt.year = endtt.year;
      newWL.enddt.hour = endtt.hoursingle;
      newWL.enddt.minute = endtt.minutesingle;

      newWL.open = false;
      newWL.islate = true;

      // calculate the totals
      const tsecs = newWL.end - newWL.start;
      if (tsecs < 0) {
        this.$buefy.snackbar.open({ message: "It seems you worked backwards in time. Please check your dates and times", position: "is-bottom", type: "is-danger", indefinite: true }); return;
      }
      newWL.totaltime = tsecs;
      const insecs = this.secondsToHours(tsecs);
      newWL.totalhours = insecs.hours;
      newWL.totalminutes = insecs.minutes;
      newWL.totalseconds = insecs.seconds;
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const addnew = await this.DataSend('post', '/worklog/new', newWL);
      this.wlMLshow = false,
      this.wlStartDate = null;
      this.wlStartTime = null;
      this.wlEndDate = null;
      this.wlEndTime = null;
      this.wlMLwhat = '';
      this.wlMLwhy = '';
      this.wlLoad();
    },

    async wlEditLogDo(inbound) {
      let newWL = {};
      if (inbound.mode === "new") {
        const TeamLive = localStorage.getItem("TeamLive");
        const TeamLiveName = localStorage.getItem("TeamLiveName");
        newWL = { ...this.worklog };
        const id = new ObjectID();
        const idout = id.str;
        newWL._id = idout;
        newWL.sub = this.user.sub;
        newWL.subname = this.user.subname;
        newWL.availableto = this.prsn.availableto;
        newWL.personid = this.prsn._id;
        newWL.person = this.prsn.firstname + " " + this.prsn.lastname;
        newWL.team = TeamLiveName;
        newWL.teamid = TeamLive;
        newWL.open = false;
        newWL.islate = true;
      } else {
        newWL = this.wlOpenSession;
      }
      for (const f of inbound.fields) {
        if (f.required) {
          if (f.value === "") {
            this.$buefy.snackbar.open({
              message: "Missing: " + f.label,
              position: "is-top"
            });
            return;
          }
        }
        if (f.field === "description") {
          newWL.description = f.value;
        }
        if (f.field === "islatereason") {
          newWL.islatereason = f.value;
        }
        if (f.field === "startdate") {
          const stdt = this.getDateFull(f.value, "none", "none", 0);
          newWL.start = stdt.ts;
          newWL.startdt.day = stdt.daysingle;
          newWL.startdt.dayofweek = stdt.dayofweek;
          newWL.startdt.dayst = stdt.dayst;
          newWL.startdt.daytxt = stdt.dayofweektxtshort;
          newWL.startdt.week = stdt.week;
          newWL.startdt.month = stdt.monthsingle;
          newWL.startdt.monthtxt = stdt.monthshort;
          newWL.startdt.quarter = stdt.quarter;
          newWL.startdt.year = stdt.year;
        }
        if (f.field === "starttime") {
          newWL.startdt.hour = parseInt(f.hour);
          newWL.startdt.minute = parseInt(f.minutes);
        }
        if (f.field === "enddate") {
          const endt = this.getDateFull(f.value, "none", "none", 0);
          newWL.end = endt.ts;
          newWL.enddt.day = endt.daysingle;
          newWL.enddt.dayofweek = endt.dayofweek;
          newWL.enddt.dayst = endt.dayst;
          newWL.enddt.daytxt = endt.dayofweektxtshort;
          newWL.enddt.week = endt.week;
          newWL.enddt.month = endt.monthsingle;
          newWL.enddt.monthtxt = endt.monthshort;
          newWL.enddt.quarter = endt.quarter;
          newWL.enddt.year = endt.year;
        }
        if (f.field === "endtime") {
          newWL.enddt.hour = parseInt(f.hour);
          newWL.enddt.minute = parseInt(f.minutes);
        }
        if (f.field === "islatestatus") {
          newWL.islatestatus = f.value;
        }
        if (f.field === "islatestatusmessage") {
          newWL.islatestatusmessage = f.value;
        }
      }

      // adjust dates
      const stdt = new Date(
        newWL.startdt.year,
        newWL.startdt.month,
        newWL.startdt.day,
        newWL.startdt.hour,
        newWL.startdt.minute,
        0,
        0
      );
      const newstdt = this.getDateFull(stdt, "none", "none", 0);
      newWL.start = newstdt.ts;
      const endt = new Date(
        newWL.enddt.year,
        newWL.enddt.month,
        newWL.enddt.day,
        newWL.enddt.hour,
        newWL.enddt.minute,
        0,
        0
      );
      const newendt = this.getDateFull(endt, "none", "none", 0);
      newWL.end = newendt.ts;

      // calculate the totals
      const tsecs = newWL.end - newWL.start;
      newWL.totaltime = tsecs;
      const insecs = this.secondsToHours(tsecs);
      newWL.totalhours = insecs.hours;
      newWL.totalminutes = insecs.minutes;
      newWL.totalseconds = insecs.seconds;
      const savelog = await this.updata(
        "worklog",
        newWL,
        "none",
        {},
        false
      );
      this.wlRows = [];
      this.wlLoad();
    },

    wlEdit() {},

    async wlUpdate(row) {
      const updl = await this.DataSend('put', '/worklog/update', row);
    },

    wlcsv() {
      const csvarr = [
        [
          "Team",
          "Client",
          "Person",
          "Hours",
          "Minutes",
          "From",
          "To",
          "Description",
          "Late",
          "Late reason",
          "billed",
          "TeamId",
          "ClientId",
          "PersonId"
        ]
      ];
      let hasSelected = false;
      for (const r of this.wlRows) {
        if (r.selected) {
          hasSelected = true;
          const stdt = this.getDateFull(
            new Date(
              r.startdt.year,
              r.startdt.month - 1,
              r.startdt.day,
              r.startdt.hour,
              r.startdt.minute,
              0,
              0
            ),
            "none",
            "none",
            0
          );
          const endt = this.getDateFull(
            new Date(
              r.enddt.year,
              r.enddt.month - 1,
              r.enddt.day,
              r.enddt.hour,
              r.enddt.minute,
              0,
              0
            ),
            "none",
            "none",
            0
          );
          const nwr = [
            r.team,
            r.person,
            r.subname,
            r.totalhours,
            r.totalminutes,
            stdt.dbtm,
            endt.dbtm,
            r.description,
            r.islate,
            r.islatereason,
            r.billed,
            r.teamid,
            r.personid,
            r.sub
          ];
          csvarr.push(nwr);
        }
      }
      if (!hasSelected) {
        this.$buefy.snackbar.open({
          message: "Select some entries to export",
          position: "is-top"
        });
        return;
      }
      this.exportToCsv(this.wlFileName, csvarr);
    },

    wlbilled() {},

    async wlChangeTeam() {
      const TeamLive = localStorage.getItem("TeamLive");
      if (TeamLive === "") {
        this.wlTeamMembers = [{ _id: "all", firstname: "Switch to a team" }];
        return;
      }
      const query = { _id: TeamLive };
      const options = { fields: { sub: 1, subname: 1, inteam: 1 } };
      const teamUsers = await this.getData("people", query, options, "none", "none", "searchextended");
      this.wlTeamMembers = [{ _id: "all", firstname: "All" }];
      for (const tm of teamUsers) {
        // this.wlTeamMembers.push({ _id: tm.sub, firstname: tm.subname });
        for (const at of tm.inteam) {
          this.wlTeamMembers.push({ _id: at.userid, firstname: at.firstname });
        }
      }
    },
    // worklog: end
    async getGoals() {
      this.GoalList = [];
      const glin = await this.axiosget("/goals/parents");
      const gl = glin.rows;

      const childarray = [];
      for (const g of gl) {
        // set style
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        g.loading = false;
        g.expanded = false;
        g.deleted = false;
        g.children = [];
        g.tasks = [];
        g.useStyle = { borderLeft: `5px solid ${g.goalcolor}` };
        // processes parents
        if (g.childof === "") {
          this.GoalList.push(g);
        } else {
          childarray.push(g);
        }
      }
    },
  },
  destroyed() {
    EventBus.$off('Select_Person');
    EventBus.$off('Associate_Person');
    EventBus.$off('from_experience');
    EventBus.$off('set_avatar');
    EventBus.$off('from_schedule');
    EventBus.$off('from_note');
    EventBus.$off('bus_wl');
  }
};
</script>

<style>
.invitemodal {
  width: 80%;
}
</style>
